import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTop from "../components/globalTop"

const Post = ({ pageContext }) => {
  const { title, content, data } = pageContext
  const fullSchema = JSON.parse(data.seo.schema.raw)

  return (
    <Layout light>
      <SEO
        title={data.seo.metaDesc ? data.seo.metaDesc : title}
        description={data.seo.metaDesc}
      />
      <GlobalTop title={title} post light />

      <section className="blog-post-content">
        <div className="container">
          <div className="blog-post-content__wrapper">
            <div dangerouslySetInnerHTML={{ __html: content }} />
            <div className="blog-post-content__wrapper-author">
              <img
                className="img-fluid"
                src={require("../assets/images/blog-user-image.jpg")}
                alt=""
              />
              <div className="blog-post-content__wrapper-author-desc">
                <h4>Agnieszka Kaszuba</h4>
                <p>
                  Jestem wykwalifikowanym podologiem, mającym wykształcenie
                  medyczne i wieloletnie doświadczenie w leczeniu różnorodnych
                  schorzeń stóp. Zajmuję się podologią leczniczą i kosmetyczną.
                  Moja praca jest moją ogromną pasją, dlatego z prawdziwą
                  przyjemnością stale doszkalam się w tej profesji. Swoim
                  Pacjentom oferuję zabiegi optymalnie dobrane do ich problemu
                  oraz profesjonalne porady dotyczące pielęgnacji stóp po
                  wyjściu z mojego gabinetu. Mogę poszczycić się funkcją
                  oficjalnego podologa MKS Cracovii Kraków, co stanowi dla mnie
                  szczególne wyróżnienie.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {fullSchema && (
        <script type="application/ld+json">
          {JSON.stringify({ ...fullSchema }, null, 2)}
        </script>
      )}
    </Layout>
  )
}

export default Post
